import React from 'react'
import ServiceSidebar from './ServiceSidebar'
import details1 from '../../../assets/images/services/services-details1.jpg'
import project2 from '../../../assets/images/projects/project2.jpg'
import charts from '../../../assets/images/services/charts.jpg'

const ServiceDetailsContent = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <img src={details1} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <span className="sub-title">Soluții eficiente</span>
                            <h3>Despre managementul și mentenanța aplicațiilor</h3>
                            <p>Managementul și întreținerea aplicațiilor eficiente sunt esențiale pentru succesul oricărei afaceri. Asigură faptul că aplicațiile sunt disponibile, fiabile și sigure și că îndeplinesc în continuare nevoile schimbătoare ale organizației. La Vertical Digital, oferim servicii complete de management și întreținere a aplicațiilor pentru a vă ajuta să optimizați performanța și valoarea aplicațiilor dvs.</p>
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>Procesul nostru</h3>
                                        <p>Procesul nostru de gestionare și întreținere a aplicațiilor include o varietate de servicii pentru a vă asigura că aplicațiile dvs. sunt bine susținute și întreținute. Acest lucru poate include monitorizare și diagnosticare, reglarea performanței, actualizări de securitate și corectarea erorilor.</p>

                                           <p>Lucrăm cu dvs. pentru a defini nevoile dvs. de gestionare și întreținere a aplicațiilor și a dezvolta un plan pentru a le îndeplini. Acest lucru poate include stabilirea acordurilor de nivel de serviciu, stabilirea obiectivelor de performanță și identificarea oricăror upgrade-uri sau îmbunătățiri necesare.</p>

                                           <p>De asemenea, oferim suport și întreținere pe termen lung pentru aplicațiile dvs., inclusiv monitorizarea oricăror probleme sau probleme și oferirea unei rezolvări rapide</p>
                                    </div>
                                </div>
                            </div>

                            <p>La Vertical Digital, suntem dedicați furnizării de servicii de gestionare și întreținere a aplicațiilor de calitate înaltă pentru a vă ajuta să optimizați performanța și valoarea aplicațiilor dvs. Echipa noastră experimentată de profesioniști vă stă la dispoziție la fiecare pas, asigurându-vă că aplicațiile dvs. sunt întotdeauna disponibile, fiabile și sigure. Contactați-ne astăzi pentru a afla mai multe despre cum vă putem ajuta să reușiți.</p>
                            <h3>Arii de aplicabilitate</h3>

                            <div className="row">
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-factory"></i>
                                        </div>
                                        Producție
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-hospital"></i>
                                        </div>
                                        Sănătate
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-tracking"></i>
                                        </div>
                                        Automotive
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-investment"></i>
                                        </div>
                                        Banking
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Imobiliare
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Logistică
                                    </div>
                                </div>
                            </div>

                            <h3>Tehnologii folosite de noi</h3>
                            <ul className="technologies-features">
                                <li><span>Java</span></li>
                                <li><span>JavaScript</span></li>
                                <li><span>Python</span></li>
                                <li><span>PHP</span></li>
                                <li><span>Swift</span></li>
                                <li><span>C# (C- Sharp)</span></li>
                                <li><span>Kotlin</span></li>
                                <li><span>SQL</span></li>
                                <li><span>Docker</span></li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebar />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContent